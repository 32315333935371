export default {

  // ROLE_ADMIN
  admin: [
    {
      name: 'Home',
      url: '/dashboard',
      icon: 'icon-home'
    },
    {
      name: 'Users',
      url: '/user',
      icon: 'icon-people',
      children: [
        {
          name: 'User Form',
          url: '/user/form',
          icon: 'icon-user'
        },
        {
          name: 'Admins',
          url: '/user/list-admins',
          icon: 'icon-list'
        },
        {
          name: 'Directors',
          url: '/user/list-directors',
          icon: 'icon-list'
        },
        {
          name: 'Coaches',
          url: '/user/list-coaches',
          icon: 'icon-list'
        },
        {
          name: 'Members',
          url: '/user/list-members',
          icon: 'icon-list'
        }
      ]
    },
    {
      name: 'Franchises',
      url: '/business',
      icon: 'icon-people',
      children: [
        {
          name: 'Form',
          url: '/business/form',
          icon: 'icon-plus'
        },
        {
          name: 'List',
          url: '/business/list',
          icon: 'icon-list'
        },
        {
          name: 'Compose',
          url: '/messages/compose/?activeDirectors=1',
          icon: 'icon-envelope'
        },
        {
          name: 'All Invoices',
          url: '/invoices',
          icon: 'icon-doc'
        }
      ]
    },
    {
      name: 'Participants',
      url: '/participants',
      icon: 'icon-people',
      children: [
        {
          name: 'Participant Form',
          url: '/participants/form',
          icon: 'icon-user'
        },
        {
          name: 'Participant List',
          url: '/participants/list',
          icon: 'icon-list'
        }
      ]
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'cui-graph',
      children: [
        {
          name: 'Revenue',
          url: '/reports/revenue/list',
          icon: 'cui-dollar'
        },
        {
          name: 'Revenue Overview',
          url: '/reports/revenue/overview',
          icon: 'cui-dollar'
        },
        {
          name: 'Marketing',
          url: '/reports/marketing/list',
          icon: 'cui-puzzle'
        }
      ]
    },
    {
      name: 'Surveys',
      url: '/surveys',
      icon: 'icon-people',
      children: [
        // {
        //   name: 'Survey Form',
        //   url: '/surveys/form',
        //   icon: 'icon-plus'
        // },
        // {
        //   name: 'Survey List',
        //   url: '/surveys/list',
        //   icon: 'icon-list'
        // },
        {
          name: 'Survey Results',
          url: '/surveys/results/1/2025/04',
          icon: 'icon-list'
        }
      ]
    },
    {
      name: 'Website',
      url: '/website',
      icon: 'icon-people',
      children: [
        {
          name: 'Custom Messages',
          url: '/website-local/custom-messages',
          icon: 'icon-envelope'
        },
        {
          name: 'Content List',
          url: '/website/content',
          icon: 'icon-list'
        },
        {
          name: 'Sports Offered',
          url: '/website/sports-offered',
          icon: 'icon-plus'
        }
      ]
    },
    {
      name: 'Support Center',
      url: '/support',
      icon: 'icon-people'
    }
  ],

  // ROLE_DIRECTOR
  director: [
    {
      name: 'Home',
      url: '/dashboard',
      icon: 'icon-home'
    },
    {
      name: 'Events',
      url: '/events',
      icon: 'icon-calendar',
      children: [
        {
          name: 'New',
          url: '/events/new',
          icon: 'icon-plus'
        },
        {
          name: 'List',
          url: '/events/all',
          icon: 'icon-list'
        },
        // {
        //   name: 'Participants',
        //   url: '/participants/list',
        //   icon: 'icon-user-following'
        // },
        {
          name: 'Registrations',
          url: '/events/registrations',
          icon: 'icon-user-following'
        }
      ]
    },
    {
      name: 'Invoices',
      url: '/invoices',
      icon: 'icon-doc'
    },
    {
      name: 'Local Website',
      url: '/website-local',
      icon: 'icon-screen-desktop',
      children: [
        {
          name: 'Pages',
          url: '/website-local/pages',
          icon: 'icon-pencil'
        },
        // {
        //   name: 'Where We Play',
        //   url: '/website-local/pages?page=locations',
        //   icon: 'icon-plus'
        // },
        {
          name: 'Happening Now',
          url: '/website-local/happening-now/form',
          icon: 'icon-plus'
        },
        {
          name: 'Happening Now',
          url: '/website-local/happening-now',
          icon: 'icon-list'
        },
        {
          name: 'Custom Messages',
          url: '/website-local/custom-messages',
          icon: 'icon-envelope'
        },
        {
          name: 'Discount Codes',
          url: '/website-local/discount-codes',
          icon: 'cui-dollar'
        },
        {
          name: 'Locations',
          url: '/website-local/locations',
          icon: 'icon-map'
        },
        {
          name: 'Settings',
          url: '/website-local/public-settings',
          icon: 'icon-options'
        },
        {
          name: 'Sponsors',
          url: '/website-local/sponsors',
          icon: 'icon-list'
        },
        {
          name: 'Important Notice',
          url: '/website-local/notice',
          icon: 'icon-exclamation'
        },
        {
          name: 'SEO',
          url: '/website-local/seo',
          icon: 'icon-magnifier'
        }
      ]
    },
    {
      name: 'Users',
      url: '/user',
      icon: 'icon-people',
      children: [
        {
          name: 'User Form',
          url: '/user/form',
          icon: 'icon-user'
        },
        {
          name: 'Members',
          url: '/user/list-members',
          icon: 'icon-list'
        },
        {
          name: 'Coaches',
          url: '/user/list-coaches',
          icon: 'icon-list'
        },
        {
          name: 'Coaches (Jr)',
          url: '/user/list-coaches-jr',
          icon: 'icon-list'
        }
      ]
    },
/*    {
      name: 'Email',
      url: '/email',
      icon: 'icon-envelope',
      children: [
        {
          name: 'Mailshots',
          url: '/email/mailshots'
        },
        {
          name: 'Groups',
          url: '/email/mailshots'
        },
        {
          name: 'Outbox',
          url: '/email/mailshots'
        }
      ]
    },*/
    {
      name: 'Reports',
      url: '/reports',
      icon: 'cui-graph',
      children: [
        {
          name: 'Revenue',
          url: '/reports/revenue/list',
          icon: 'cui-dollar'
        },
        {
          name: 'Submit Revenue',
          url: '/reports/revenue/form',
          icon: 'cui-note'
        },
        {
          name: 'Marketing',
          url: '/reports/marketing/list',
          icon: 'cui-puzzle'
        },
        {
          name: 'Marketing Overview',
          url: '/reports/marketing/overview',
          icon: 'cui-graph'
        },
        {
          name: 'Submit Marketing',
          url: '/reports/marketing/form',
          icon: 'cui-note'
        }
      ]
    },
    {
      name: 'Applicants',
      url: '/applicants',
      icon: 'icon-folder'
    },
    {
      name: 'Support Center',
      url: '/support',
      icon: 'icon-support'
    },
    {
      name: 'Supplies',
      title: 'Supplies',
      icon: 'icon-folder'
    },
    {
      name: 'Gear Site',
      attributes: { target: '_blank' },
      url: 'https://gear.nzonesports.com/',
      icon: 'icon-basket-loaded'
    },
    {
      name: 'Build A Sign',
      attributes: { target: '_blank' },
      url: 'https://nzonesports.buildasign.com',
      icon: 'icon-basket-loaded'
    },
  ],

  // ROLE COACH
  coach: [
    {
      name: 'Home',
      url: '/',
      icon: 'icon-home'
    },
    // {
    //   name: 'Support Center',
    //   url: '/support',
    //   icon: 'icon-support'
    // },
    // {
    //   name: 'Email',
    //   url: '/email',
    //   icon: 'icon-envelope'
    // }
  ],

  // ROLE COACH (Jr)
  coach_jr: [
    {
      name: 'Home',
      url: '/dashboard',
      icon: 'icon-home'
    },
    // {
    //   name: 'Support Center',
    //   url: '/support',
    //   icon: 'icon-support'
    // },
    // {
    //   name: 'Email',
    //   url: '/email',
    //   icon: 'icon-envelope'
    // }
  ]
}